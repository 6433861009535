<template>
  <div>
    <b-row v-if="!isLoading">
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-book small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Panduan
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col>
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block search-min-width"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'fullname', type: 'asc' },
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'file'" class="text-nowrap">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1"
                    :href="props.row.file"
                    target="_blank"
                  >
                    <i class="ti ti-download" />
                    Download
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Data Siswa
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Utils
import { pascalCase, avatar } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import client from '@/libs/http/axios-config'

// *Component
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,

    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // State
      isLoading: true,
      buttonLoadingId: null,

      // Filter
      selectedClassroom: 'Semua Kelas',
      classroomList: [],

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Panduan',
          field: 'guidename',
        },
        {
          label: 'Download File',
          field: 'file',
          width: '15%',
        },
      ],
      rows: [
        {
          guidename: 'Manual Book Kelas Jateng Admin Sekolah',
          file: '/guide/Manual Book Kelas Jateng_Admin Sekolah.pdf',
        },
        {
          guidename: 'Manual Book Kelas Jateng Guru',
          file: '/guide/Manual Book Kelas Jateng_Guru.pdf',
        },
        {
          guidename: 'Manual Book Kelas Jateng Siswa',
          file: '/guide/Manual Book Kelas Jateng_Siswa.pdf',
        },
      ],
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    pascalCase,
    checkAuthorizeRole,
    showImportModal() {
      this.$bvModal.show('student-import-modal')
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}
</style>
